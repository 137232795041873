import React from 'react';
import { graphql, Link, PageProps } from 'gatsby';
import Layout from '../components/layout';
import { PageAboutWebsiteQuery } from '../../graphql-types';

const AboutWebsite = ({ data }: PageProps<PageAboutWebsiteQuery>): JSX.Element => {
  const { allNpmLicense } = data;
  const { nodes } = allNpmLicense;
  return (
    <Layout seo={{ title: '本サイトについて' }}>
      <section className="section">
        <div className="container">
          <h1 className="title is-spaced">本サイトについて</h1>
          <h2 className="subtitle">オープンソース ライセンス</h2>
          <p className="block">本サイトは下記のオープンソースを利用して作成しています。</p>
          <ul className="block">
            {nodes.map((node) => (
              <li className="is-size-6"><Link className="has-text-dark" to={`/oss-license/${node.name}`}>{node.name}</Link></li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  );
};

export default AboutWebsite;

export const pageQuery = graphql`
  query PageAboutWebsite {
    allNpmLicense {
      nodes {
        name
      }
    }
  }
`;
