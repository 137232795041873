import React from 'react';
import FooterContent from './footer-content';
import HeaderContent from './header-content';
import Seo, { SeoData } from './seo';

const Layout = ({ seo, children }: {
  seo: SeoData;
  children: JSX.Element | JSX.Element[];
}): JSX.Element => (
  <div>
    <Seo
      description={seo.description}
      meta={seo.meta}
      title={seo.title}
    />
    <header className="has-background-primary">
      <HeaderContent blogUrl="https://tech-blog.re-arc-lab.jp/" />
    </header>
    <main>
      {children}
    </main>
    <footer className="footer has-background-primary">
      <FooterContent blogUrl="https://tech-blog.re-arc-lab.jp/" />
    </footer>
  </div>
);
export default Layout;
